import React from "react";

import Layout from "../components/organisms/layout";
import Footer from "../components/organisms/footer";
import Header from "../components/organisms/header";
import Contact from "../components/organisms/contact";

import Nav from "../components/molecules/nav";
import SEO from "../components/molecules/seo";

const WaaromRitorno = () => (
  <Layout>
    <SEO
      title="Waarom ritorno"
      description="Op uw feest bakken wij een dozijn verschillende soorten pizza’s,
                waarbij enkele zeer exquisiete, de een al lekkerder dan de
                ander. Ze worden in punten gesneden zodat ieder de grote
                variëteit aan pizza’s kan appreciëren. Ieder eet in ieder geval
                zoveel hij/zij wil. Op vraag bakken wij ook lactose – of
                glutenvrije pizza’s"
    />

    <Nav />

    <Header />

    <section
      className="pattern-bg pt-7 pb-5"
      style={{ backgroundImage: "url(/design/img/pattern-background.png)" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-sm-8 mx-auto">
            <p className="text-center">
              Ritorno staat voor een trendy en stijlvol event waarbij ons
              professioneel personeel garant staat voor een zeer vlot en uiterst
              gezellig verloop. Wij verzekeren u dat uw genodigden gecharmeerd
              zullen zijn van ons werkelijk zeer mooi pastakraam en onze echt
              zeer exquisiete pastagerechten. Deze worden bovendien op een zeer
              originele manier aan huis gebracht. Onze pasta’s worden vers
              geproduceerd en al de ingrediënten van de sauzen zijn enkel van
              absolute topkwaliteit.
            </p>
          </div>
          <div className="col-sm-12 mt-5 d-flex justify-content-center">
            <img
              src="/design/img/center-block-background.png"
              className="screen"
              alt="Onze Authentieke oven"
            />{" "}
          </div>
        </div>
        <hr className="mt-5" />
      </div>
    </section>
    <section
      className="pattern-bg pb-5"
      style={{ backgroundImage: "url(/design/img/pattern-background.png)" }}
    >
      <div className="container">
        <div className="row eh-grid">
          <div className="col-sm-6 col-md-4">
            <div className="list-item-center">
              <img
                src="/design/img/2020/pasta-sketch.png"
                alt="Uitgebreid assortiment"
                className="screen"
              />
              <h3 className="mt-2">Assortiment</h3>
              <p>
                Rigatone, Fusili, Pappardelle,… overheerlijke pasta op uw
                familiefeest, receptie, communiefeest, walking dinner,
                bedrijfsfeest…
              </p>
            </div>
          </div>
          <div className="col-sm-6 col-md-4">
            <div className="list-item-center">
              <img
                src="/design/img/feature-food6.png"
                alt="Verse en kwaliteitsvolle pizza's"
                className="screen"
              />
              <h3 className="mt-2">Vers en kwaliteit</h3>
              <p>
                Zes verrukkelijke pastasauzen die één voor één beantwoorden aan
                uw hoogste eisen qua smaak, kwaliteit en versheid.
              </p>
            </div>
          </div>
          <div className="col-sm-6 col-md-4">
            <div className="list-item-center">
              <img
                src="/design/img/feature-food2.png"
                alt="Mobiel pizzakraam bij u thuis"
                className="screen"
              />
              <h3 className="mt-2">Presentatie</h3>
              <p>
                Uw genodigden komen zo vaak als ze willen aan ons uitnodigend
                kraam en dit om ongebreideld te genieten van ons zeer
                verscheiden assortiment aan supersmakelijke pastagerechten.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Contact />

    <Footer />
  </Layout>
);

export default WaaromRitorno;
